import * as React from 'react';
import BaseTab from './BaseTab';
import { ROUTES } from '../../../routes';

export const tabsMapping = {
  // L1 Tabs
  UserManagementTab: (
    <BaseTab
      label="User Management"
      do={[
        'read_user_country_manager',
        'read_user_sales_manager',
        'read_business_partner_admin',
        'read_user_partner_admin',
        'read_user_store_manager',
        'read_user_sales_representative',
      ]}
      to={ROUTES.USER_MANAGEMENT.path}
      value={ROUTES.USER_MANAGEMENT.path}
    />
  ),
  ManagementTab: (
    <BaseTab
      label="asurion.common.navigation.menus.management"
      do={[
        'read_user_country_manager',
        'read_user_sales_manager',
        'read_user_partner_admin',
        'read_user_store_manager',
        'read_user_sales_representative',
      ]}
      to={ROUTES.MANAGEMENT.path}
      value={ROUTES.MANAGEMENT.path}
    />
  ),
  AccountManagementTab: (
    <BaseTab
      label="Account Management"
      do={['read_partners', 'read_store']}
      to={ROUTES.ACCOUNT_MANAGEMENT.path}
      value={ROUTES.ACCOUNT_MANAGEMENT.path}
    />
  ),
  SalesPerformanceTab: (
    <BaseTab
      label="asurion.common.navigation.menus.sales_performance"
      do={[
        'view_partner_sales',
        'view_programs',
        'view_phone_make',
        'view_phone_make_by_programs',
        'view_gross_adds_by_partners',

        'view_enrollment_report',
      ]}
      to={ROUTES.SALES_PERFORMANCE.path}
      value={ROUTES.SALES_PERFORMANCE.path}
    />
  ),
  BulletinTab: (
    <BaseTab
      label="asurion.common.navigation.menus.bulletin"
      do={['view_bulletin']}
      to={ROUTES.BULLETIN.path}
      value={ROUTES.BULLETIN.path}
    />
  ),
  // ETrainingResourcesTab: (
  //  <BaseTab
  //    label="eTraining Resources"
  //    do={['view_etraining']}
  //    to={ROUTES.ETRAINING_RESOURCES.path}
  //    value={ROUTES.ETRAINING_RESOURCES.path}
  //  />
  // ),
  IncentivesTab: (
    <BaseTab
      label="asurion.common.navigation.menus.incentives"
      do={['view_incentives']}
      to={ROUTES.INCENTIVES.path}
      value={ROUTES.INCENTIVES.path}
    />
  ),
  IncentivesCalculationTab: (
    <BaseTab
      label="Incentive Calculation"
      do={['calculate_incentives']}
      to={ROUTES.INCENTIVES_CALCULATION.path}
      value={ROUTES.INCENTIVES_CALCULATION.path}
    />
  ),
  // End L1 tabs

  CountryManagerTab: (
    <BaseTab
      label="Asurion Country Manager"
      do="read_user_country_manager"
      to={ROUTES.COUNTRY_MANAGERS.path}
      value={ROUTES.COUNTRY_MANAGERS.path}
    />
  ),
  SalesManagerTab: (
    <BaseTab
      label="asurion.common.navigation.menus.sales_manager"
      do="read_user_sales_manager"
      to={ROUTES.SALES_MANAGERS.path}
      value={ROUTES.SALES_MANAGERS.path}
    />
  ),
  BusinessPartnerTab: (
    <BaseTab
      label="asurion.common.navigation.menus.business_partner"
      do="read_business_partner"
      to={ROUTES.BUSINESS_PARTNERS.path}
      value={ROUTES.BUSINESS_PARTNERS.path}
    />
  ),
  BusinessPartnerAdminTab: (
    <BaseTab
      label="asurion.common.navigation.menus.business_partner_admin"
      do="read_user_business_partner_admin"
      to={ROUTES.BUSINESS_PARTNER_ADMINS.path}
      value={ROUTES.BUSINESS_PARTNER_ADMINS.path}
    />
  ),
  PartnerAdminTab: (
    <BaseTab
      label="asurion.common.navigation.menus.partner_admin"
      do="read_user_partner_admin"
      to={ROUTES.PARTNER_ADMINS.path}
      value={ROUTES.PARTNER_ADMINS.path}
    />
  ),
  StoreManagerTab: (
    <BaseTab
      label="asurion.common.navigation.menus.store_manager"
      do="read_user_store_manager"
      to={ROUTES.STORE_MANAGERS.path}
      value={ROUTES.STORE_MANAGERS.path}
    />
  ),
  SalesRepresentativeTab: (
    <BaseTab
      label="asurion.common.navigation.menus.sales_representative"
      do="read_user_sales_representative"
      to={ROUTES.SALES_REPRESENTATIVES.path}
      value={ROUTES.SALES_REPRESENTATIVES.path}
    />
  ),
  SalesPartnerTab: (
    <BaseTab
      label="asurion.common.navigation.menus.partner_account"
      do="read_partners"
      to={ROUTES.SALES_PARTNERS.path}
      value={ROUTES.SALES_PARTNERS.path}
    />
  ),
  StoreAccountTab: (
    <BaseTab
      label="asurion.common.navigation.menus.store_account"
      do="read_store"
      to={ROUTES.PARTNER_STORES.path}
      value={ROUTES.PARTNER_STORES.path}
    />
  ),
  // MANAGEMENT
  StoreAccountManagementTab: (
    <BaseTab
      label="asurion.common.navigation.menus.store_account"
      do="read_store"
      to={ROUTES.PARTNER_STORES_MANAGEMENT.path}
      value={ROUTES.PARTNER_STORES_MANAGEMENT.path}
    />
  ),
  StoreManagerManagementTab: (
    <BaseTab
      label="asurion.common.navigation.menus.store_manager"
      do="read_user_store_manager"
      to={ROUTES.STORE_MANAGERS_MANAGEMENT.path}
      value={ROUTES.STORE_MANAGERS_MANAGEMENT.path}
    />
  ),
  SalesRepresentativeManagementTab: (
    <BaseTab
      label="asurion.common.navigation.menus.sales_representative"
      do="read_user_sales_representative"
      to={ROUTES.SALES_REPRESENTATIVES_MANAGEMENT.path}
      value={ROUTES.SALES_REPRESENTATIVES_MANAGEMENT.path}
    />
  ),
  // END MANAGEMENT
  PartnerSalesTab: (
    <BaseTab
      label="Partner Sales"
      do="view_partner_sales"
      to={ROUTES.PARTNER_SALES.path}
      value={ROUTES.PARTNER_SALES.path}
    />
  ),
  ProgramsTab: (
    <BaseTab
      label="Programs"
      do="view_programs"
      to={ROUTES.PROGRAMS.path}
      value={ROUTES.PROGRAMS.path}
    />
  ),
  PhoneMakeTab: (
    <BaseTab
      label="Phone Make"
      do="view_phone_make"
      to={ROUTES.PHONE_MAKE.path}
      value={ROUTES.PHONE_MAKE.path}
    />
  ),
  PhoneMakeByProgramsTab: (
    <BaseTab
      label="Phone Make by Programs"
      do="view_phone_make_by_programs"
      to={ROUTES.PHONE_MAKE_BY_PROGRAMS.path}
      value={ROUTES.PHONE_MAKE_BY_PROGRAMS.path}
    />
  ),
  GrossAddsByPartnersTab: (
    <BaseTab
      label="Gross Adds by Partners"
      do="view_gross_adds_by_partners"
      to={ROUTES.GROSS_ADDS_BY_PARTNERS.path}
      value={ROUTES.GROSS_ADDS_BY_PARTNERS.path}
    />
  ),

  // SALES MANAGEMENT PERFORMANCE
  PartnerSalesPerformanceTab: (
    <BaseTab
      label="asurion.common.navigation.menus.partner_sales"
      do="view_partner_sales"
      to={ROUTES.PARTNER_SALES_PERFORMANCE.path}
      value={ROUTES.PARTNER_SALES_PERFORMANCE.path}
    />
  ),
  ProgramsPerformanceTab: (
    <BaseTab
      label="asurion.common.navigation.menus.programs"
      do="view_programs"
      to={ROUTES.PROGRAMS_PERFORMANCE.path}
      value={ROUTES.PROGRAMS_PERFORMANCE.path}
    />
  ),
  PhoneMakePerformanceTab: (
    <BaseTab
      label="asurion.common.navigation.menus.phone_make"
      do="view_phone_make"
      to={ROUTES.PHONE_MAKE_PERFORMANCE.path}
      value={ROUTES.PHONE_MAKE_PERFORMANCE.path}
    />
  ),
  PhoneMakeByPartnerTab: (
    <BaseTab
      label="Phone Make by Partner"
      do="view_phone_make_by_partner"
      to={ROUTES.PHONE_MAKE_BY_PARTNER.path}
      value={ROUTES.PHONE_MAKE_BY_PARTNER.path}
    />
  ),

  // Enrollment Report
  WeeklyEnrollmentReportTab: (
    <BaseTab
      label="Daily/Weekly Enrollment"
      do="view_enrollment_report"
      to={ROUTES.ENROLLMENT_REPORT.path}
      value={ROUTES.ENROLLMENT_REPORT.path}
    />
  ),
};

export default tabsMapping;
