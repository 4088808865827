import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import { IconButton, IconButtonProps } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useCountry } from '../../../../contexts/CountryContext';

const SignoutButton = forwardRef<
  any,
  IconButtonProps & { onBeforeLogout?: Function }
>((props, ref) => {
  const logout = useLogout();
  const { setCountry } = useCountry();
  const handleClick = () => {
    if (props.onBeforeLogout) {
      props.onBeforeLogout();
    }
    setCountry(null);
    localStorage.clear();
    logout();
  };
  return (
    <IconButton ref={ref} color="inherit" onClick={handleClick} {...props}>
      <ExitToAppIcon />
    </IconButton>
  );
});

export default SignoutButton;
