import { noop } from '../common/functions';
import { ROLES } from '../common/types';
import { isFinanceUser } from '../common/util';

import { ACCESS_TOKEN, API_TOKEN } from '../providers/authProvider';
import { fetchJson } from '../providers/dataProvider';
import { identify } from 'react-fullstory';

import { getPendingIncentiveCalculation } from '../services/apiService';
import { SHA512 } from 'crypto-js';
import JwtUtil from './JwtUtil';
import { decodeJwt } from 'jose';

export const processPermissions = async (email: string, token: string) => {
  // TODO: Transfer to apiService
  const roleEndpoint = `${process.env.REACT_APP_ADMIN_API_URL}/role/${SHA512(
    email.toLowerCase()
  ).toString()}`;
  const { body } = await fetchJson(roleEndpoint, {}, false);
  const reconned = JwtUtil.reconstructToken(body.replaceAll('"', ''));
  const { data }: any = decodeJwt(reconned);
  const user = JSON.parse(data);
  const { role = 'CUSTOMER' } = user;

  const hasBusinessPartnerFeature = ['phl', 'twn'].includes(
    user.extra?.countryId
  );

  const permissionSet: { [key in ROLES]: Array<string> } = {
    SYSTEM_ADMIN: [
      'is_super_admin',

      'create_user_country_manager',
      'read_user_country_manager',
      'update_user_country_manager',

      'create_user_sales_manager',
      'read_user_sales_manager',
      'update_user_sales_manager',

      'create_user_partner_admin',
      'read_user_partner_admin',
      'update_user_partner_admin',

      'create_store',
      'read_store',
      'update_store',

      'create_user_store_manager',
      'read_user_store_manager',
      'update_user_store_manager',

      'create_user_sales_representative',
      'read_user_sales_representative',
      'update_user_sales_representative',

      'change_store_manager_assignment',

      'create_partners',
      'read_partners',
      'update_partners',

      'view_partner_sales',
      'view_programs',
      'view_phone_make',
      'view_phone_make_by_programs',
      'view_gross_adds_by_partners',

      'view_bulletin',
      'view_etraining',

      'view_incentives',

      'create_user_business_partner_admin',
      'read_user_business_partner_admin',
      'update_user_business_partner_admin',

      'create_business_partner',
      'read_business_partner',
      'update_business_partner',

      'view_enrollment_report',
    ],
    COUNTRY_MGR: [
      'view_partner_sales',
      'view_programs',
      'view_phone_make',
      'view_phone_make_by_programs',
      'view_gross_adds_by_partners',

      'view_incentives',
    ],
    SALES_MGR: [
      'read_store',
      'read_user_store_manager',
      'read_user_sales_representative',

      ...(hasBusinessPartnerFeature
        ? [
            'create_user_business_partner_admin',
            'read_user_business_partner_admin',
            'update_user_business_partner_admin',

            'create_business_partner',
            'read_business_partner',
            'update_business_partner',
          ]
        : []),

      'view_partner_sales',
      'view_programs',
      'view_phone_make',
      'view_phone_make_by_programs',
      'view_phone_make_by_partner',
      'view_bulletin',

      'view_enrollment_report',
    ],
    PARTNER_ADMIN: [
      'create_store',
      'read_store',
      'update_store',

      'create_user_store_manager',
      'read_user_store_manager',
      'update_user_store_manager',

      'create_user_sales_representative',
      'read_user_sales_representative',
      'update_user_sales_representative',

      'create_user_partner_admin',
      'read_user_partner_admin',
      'update_user_partner_admin',

      'view_partner_sales',
      'view_programs',
      'view_phone_make',
      'view_phone_make_by_programs',
      'view_phone_make_by_partner',
      'view_bulletin',

      'view_incentives',
      'view_own_profile',

      'view_enrollment_report',
    ],
    ANONYMOUS: ['guest'],
    CUSTOMER: ['guest'],
    SALES_REP: ['guest'],
    STORE_MGR: ['guest'],
    FINANCE_MGR: [
      'view_partner_sales',
      'view_programs',
      'view_phone_make',
      'view_phone_make_by_programs',
      'view_gross_adds_by_partners',
      'view_incentives',
      'calculate_incentives',
      'view_incentives_calculation',
      'payout_incentives',
    ],
    BUSINESS_PARTNER_ADMIN: [
      'is_business_partner_admin',

      'create_user_partner_admin',
      'read_user_partner_admin',
      'update_user_partner_admin',

      'create_partners',
      'read_partners',
      'update_partners',

      'view_bulletin',

      'view_enrollment_report',
    ],
  };
  let permissions = permissionSet[role as ROLES];

  if (['phl', 'twn'].includes(user.extra?.countryId)) {
    permissions.push('no_bank_details');
    const reportPermissions = [
      'view_partner_sales',
      'view_programs',
      'view_phone_make',
      'view_phone_make_by_programs',
      'view_phone_make_by_partner',
      'view_bulletin',
    ];
    permissions = permissions.filter(
      (permission) => !reportPermissions.includes(permission)
    );
  }

  // localStorage.setItem('permissions', JSON.stringify(permissions));
  localStorage.setItem(
    'permissions',
    await JwtUtil.generate(
      JSON.stringify(permissions),
      (60 * 60 * 24 * 365).toString(),
      true
    )
  );
  localStorage.setItem(
    'user',
    await JwtUtil.generate(user, (60 * 60 * 24 * 365).toString(), true)
  );
  sessionStorage.setItem(ACCESS_TOKEN, token);

  return { user, permissions };
};

export const postAuthentication = async (identity: any, api_token: string) => {
  console.log('Doing post authentication processes...');

  identify(identity.id, {
    displayName: `${identity.firstName} ${identity.lastName}`,
    email: identity.userEmail,
  });

  sessionStorage.setItem(API_TOKEN, api_token);

  console.log('Checking for role-dependent post-login setup...');
  if (isFinanceUser(identity)) {
    await getPendingIncentiveCalculation(identity?.id, true, noop);
  }
};
