import * as React from 'react';
import {
  required,
  SelectInput,
  SelectInputProps,
  useGetIdentity,
} from 'react-admin';
import { useCountryId } from '../../../../contexts/CountryContext';
import { DEFAULT_COUNTRY_LIST as SOURCE } from '../../../../common/constants';
import _ from 'lodash';

export const DEFAULT_SOURCE = 'country';

export const DEFAULT_COUNTRY_LIST = _.map(
  SOURCE,
  _.partialRight<any, Partial<(typeof SOURCE)[number]>>(_.pick, ['id', 'name'])
);

export const CountrySelector = ({
  source = DEFAULT_SOURCE,
  choices = DEFAULT_COUNTRY_LIST,
  options = {
    placeholder: 'Select Country',
  },
  validate = [required()],
  variant = 'outlined',
  disabled = true,
  ...restProps
}: SelectInputProps) => {
  const { countryId: country } = useCountryId();
  const { identity } = useGetIdentity();

  if (!country && !identity?.extra.countryId) {
    disabled = false;
  }
  return (
    <SelectInput
      initialValue={country || identity?.extra.countryId}
      {...{
        source,
        choices,
        options,
        validate,
        variant,
        disabled,
      }}
      fullWidth
      variant="outlined"
      {...restProps}
    />
  );
};

export default CountrySelector;
