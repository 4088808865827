import * as jose from 'jose';

export default class JwtUtil {
  public static async generate(
    payload: string,
    expiredIn: string,
    deconstruct: boolean = false,
    usePld: boolean = false
  ) {
    const jwtSecret = new TextEncoder().encode(
      process.env.REACT_APP_JWT_SECRET as any
    );

    const token = await new jose.SignJWT(
      usePld ? { pld: JSON.parse(payload) } : { data: payload }
    )
      .setProtectedHeader({ alg: 'HS256' })
      .setExpirationTime(`${expiredIn}s`)
      .sign(jwtSecret);

    return deconstruct ? this.deconstructToken(token) : token;
  }

  private static deconstructToken(token: string) {
    const tokenPart = token.split('.');

    return (
      this.reverse(tokenPart[0].replace('eyJ', '')) +
      ';' +
      this.reverse(tokenPart[1].replace('eyJ', '')) +
      ';' +
      tokenPart[2]
    );
  }

  public static reconstructToken(token: string) {
    const tokenPart = token.split(';');
    return (
      'eyJ' +
      this.reverse(tokenPart[0]) +
      '.' +
      'eyJ' +
      this.reverse(tokenPart[1]) +
      '.' +
      tokenPart[2]
    );
  }

  private static reverse(data: string) {
    let dataSplit = data.split('');
    let dataReverse = dataSplit.reverse();
    let dataJoined: string = dataReverse.join('');

    return dataJoined;
  }
}
