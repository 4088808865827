import * as React from 'react';
import { createTheme } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { LayoutProps } from 'ra-ui-materialui';
import { Notification, resolveBrowserLocale, useSetLocale } from 'react-admin';
import DefaultAppBar from './DefaultAppBar';
import SecondaryNavigation from './SecondaryNavigation';
import { GlobalCss } from '../themes/GlobalCss';
import { ThemeConfigProvider } from '../contexts/ThemeContext';
import { SalesPartnerProvider } from '../contexts/SalesPartnerContext';
import { DoneModalProvider } from '../contexts/DoneModalContext';
import { NavigationProvider } from '../contexts/NavigationContext';
import { ProfileProvider } from '../contexts/ProfileContext';
import { useCountry } from '../contexts/CountryContext';

const useStyles = makeStyles({
  root: {},
  main: {
    marginTop: 60,
  },
});

const DefaultLayout: React.FC<LayoutProps> = ({
  theme: _theme,
  children,
  ...restProps
}) => {
  const theme = createTheme(_theme);
  const classes = useStyles();
  const { country } = useCountry();
  const setLocale = useSetLocale();

  React.useEffect(() => {
    if (
      country &&
      country.defaultLanguage &&
      country.defaultLanguage === 'en-SGP'
    ) {
      setLocale(country.defaultLanguage);
    } else {
      setLocale(resolveBrowserLocale());
    }
  }, [country, setLocale]);

  return (
    <ThemeConfigProvider neutralBackground={false}>
      <ThemeProvider theme={theme}>
        <GlobalCss />

        <SalesPartnerProvider>
          <DoneModalProvider>
            <ProfileProvider>
              <div className={classes.root}>
                <NavigationProvider>
                  <DefaultAppBar />
                  <SecondaryNavigation />
                </NavigationProvider>
                <main className={classes.main}>
                  <div>{children}</div>
                </main>
                <Notification />
              </div>
            </ProfileProvider>
          </DoneModalProvider>
        </SalesPartnerProvider>
      </ThemeProvider>
    </ThemeConfigProvider>
  );
};

export default DefaultLayout;
