import { createTheme, lighten } from '@material-ui/core';
import { colors as defaultColors, theme as defaultTheme } from './default';
import _ from 'lodash';

export const colors: typeof defaultColors = _.merge({}, defaultColors, {
  brand: {
    bg: {
      dark: '#FF001b',
    },
  },
  extra: {
    indicator: '#FF001b',
    header: {
      background: '#FF0033',
    },
  },
});

export const theme = createTheme(
  _.merge({}, defaultTheme, {
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: colors.brand.bg.dark,
          color: colors.brand.font.dark,
          '&:hover': {
            backgroundColor: lighten(colors.brand.bg.dark, 0.3),
          },
        },
      },
      RaButton: {
        button: {
          backgroundColor: colors.brand.bg.dark,
          color: '#fff',
          '&:hover': {
            backgroundColor: lighten(colors.brand.bg.dark, 0.3),
          },
        },
      },
    },
  })
);

export default theme;
