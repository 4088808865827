import * as React from 'react';
import {
  Create,
  CreateProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { useCountryId } from '../../contexts/CountryContext';
import ContactNumber from '../common/Field/ContactNumber';
import AsurionForm from '../common/Form/AsurionForm';
import { EmailInput } from '../common/Form/Input/EmailInput';

export const SalesManagerCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const { countryId } = useCountryId();

  const transform = (data: any) => ({
    ...data,
    countryId,
    created_at: Date.now(),
    status: 'Pending',
  });

  return (
    <Create {...props} transform={transform}>
      <AsurionForm
        title={translate('resources.sales-managers.new_title')}
        redirect="list"
      >
        <TextInput
          label="First Name"
          placeholder="e.g. John"
          source="firstName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          label="Last Name"
          placeholder="e.g. Smith"
          source="lastName"
          fullWidth
          validate={required()}
          className="required"
        />
        <TextInput
          label="Nickname"
          placeholder="e.g. John"
          source="nickname"
          fullWidth
        />
        <EmailInput source="userEmail" className="required" />
        <ContactNumber
          label="asurion.common.form.contact_number.label"
          source="contactNumber"
          className="required"
        />
        <ReferenceInput
          label="Country Manager"
          source="countryManagerId"
          reference="country-managers"
          fullWidth
          validate={required()}
          filter={{
            countryId,
            activeIndStatus: 'active',
          }}
          className="required"
        >
          <SelectInput
            optionText={({
              firstName,
              lastName,
            }: {
              firstName: string;
              lastName: string;
            }) => `${firstName} ${lastName}`}
          />
        </ReferenceInput>
      </AsurionForm>
    </Create>
  );
};

export default SalesManagerCreate;
