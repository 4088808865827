import _ from 'lodash';
import englishMessages from './en';

export const englishSgpMessages = _.merge({}, englishMessages, {
  resources: {
    'store-managers': {
      name: 'Shop Manager |||| Shop Managers',
      new_title: 'New Shop Manager',
    },
    'sales-partners': {
      fields: {
        salesManagers: 'Sales Director',
      },
    },
    'sales-managers': {
      name: 'Sales Director |||| Sales Directors',
      new_title: 'New Sales Director',
      edit_title: 'Edit Sales Director',
    },
  },
  asurion: {
    common: {
      navigation: {
        menus: {
          store_manager: 'Shop Manager',
          sales_representative: 'Sales Person',
          sales_manager: 'Sales Director',
          sales_performance: 'Reporting',
        },
      },
    },
  },
});

export default englishSgpMessages;
