import { createContext, useContext, useEffect, useState } from 'react';
import { COUNTRY_KEY } from '../common/constants';
import { theme as sgpTheme, colors as sgpColors } from '../themes/sgp';
import { colors as defaultColors } from '../themes/default';
import SgpLogoIcon from '../components/common/Branding/SGP/SgpLogo';

export interface CountryContextProps {
  country?: {
    id: string;
    name: string;
    defaultLanguage?: string;
    maxStoresPerManager?: number;
  };
  setCountry?: any;
  children?: React.ReactNode;
}

const defaultCountry = JSON.parse(localStorage.getItem(COUNTRY_KEY) || '{}');

const CountryContext = createContext<CountryContextProps>({
  country: defaultCountry,
});

export const CountryProvider = ({
  country: inputCountry = defaultCountry,
  children,
}: CountryContextProps) => {
  const [country, setCountry] = useState(inputCountry);
  useEffect(() => {
    if (country === null) {
      localStorage.removeItem(COUNTRY_KEY);
    } else {
      localStorage.setItem(
        COUNTRY_KEY,
        JSON.stringify(country || '{ "id": "" }')
      );
    }
  }, [country]);

  return (
    <CountryContext.Provider value={{ country, setCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => useContext(CountryContext);
export const useCountryId = () => {
  const { country } = useContext(CountryContext);
  return {
    countryId: country?.id || null,
  };
};

export const useCountryConfig = () => {
  const [theme, setTheme] = useState<any>(null);
  const [logo, setLogo] = useState<React.ReactNode>(null);
  const [colors, setColors] = useState<{}>(defaultColors);
  const { country } = useContext(CountryContext);
  let maxStoresPerManager = country?.maxStoresPerManager ?? 3;

  useEffect(() => {
    switch (country?.id) {
      case 'sgp':
        setTheme(sgpTheme);
        setColors(sgpColors);
        setLogo(SgpLogoIcon);
        break;
      default:
        setTheme(null);
        setColors(defaultColors);
        setLogo(null);
        break;
    }
  }, [country]);

  return {
    theme,
    colors,
    logo,
    hasBusinessPartner: ['phl', 'twn'].includes(country?.id || ''),
    MAX_STORES_PER_MANAGER: maxStoresPerManager,
  };
};
